<template>
  <div class="notice">
    <div class="nav">
      <Header></Header>
      <div class="nav_flex">
        <div class="title">{{ title }}</div>
        <!-- <img class="title" :src="require('./static/image/title.png')" alt="" /> -->
        <div class="search">
          <img :src="require('./static/image/search.png')" alt="" />
          <input
            v-model="keyword"
            type="text"
            placeholder="请输入关键词"
            placeholder_class="placeholder_class"
            @input="searchInput()"
          />
          <div class="button" @click="searchGetNoticeData()">搜索</div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="info_div">
        <div class="title">
          <div class="name">通知公告</div>
          <img
            class="icon"
            :src="require('./static/image/announcement.png')"
            alt=""
          />
        </div>
        <div class="list">
          <div
            class="list_div"
            v-for="(item, index) in announcement1Arr"
            :key="index"
            @click="toListDetail(item.noticeId, 0)"
          >
            <div>{{ item.noticeTitle }}</div>
            <div>{{ item.createTimeDate }}</div>
          </div>
        </div>
        <div class="paging">
          <Page
            :total="page.total"
            size="default"
            :page-size-opts="[10, 20, 30, 40]"
            show-total
            show-elevator
            show-sizer
            @on-change="onChange"
            @on-page-size-change="onSizeChange"
          />
        </div>
      </div>
    </div>
    <Fotter></Fotter>
  </div>
</template>
<script>
import Header from "../../components/common/header.vue";
import Fotter from "../../components/common/fotter.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { getNoticeData } from "../../request/home.js";
export default {
  name: "notice",
  components: { Header, Fotter },
  data() {
    return {
      announcement1Arr: [],
      keyword: "",
      page: {
        current: 1,
        size: 10,
        total: 0,
        keyword: "",
      },
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.title.title,
    }),
  },
  mounted() {
    this.getNoticeData();
  },
  methods: {
    toListDetail(id) {
      this.$router.push({
        path: "/list_detail",
        query: { noticeId: id, type1: 0, type2: 0 },
      });
    },
    onChange(page) {
      this.page.current = page;
      this.getNoticeData();
    },
    onSizeChange(pageSize) {
      this.page.size = pageSize;
      this.getNoticeData();
    },
    getNoticeData() {
      getNoticeData({
        current: this.page.current,
        size: this.page.size,
        keyword: this.page.keyword,
        noticeType: 3,
      }).then((res) => {
        this.announcement1Arr = res.data.records;
        this.page.total = res.data.total;
      });
    },
    searchInput() {
      if (this.keyword == "") {
        this.searchGetNoticeData();
      }
    },
    searchGetNoticeData() {
      this.page.current = 1;
      this.page.keyword = this.keyword;
      this.getNoticeData();
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.notice {
  // width: 1920px;
  min-height: 100vh;
  background: #f2f3f7;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 150px;

  .nav {
    height: 251px;
    background-image: url("./static/image/nav.png");
    background-size: 100% 100%;

    .nav_flex {
      height: 155px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      .title {
        // width: 383px;
        height: 68px;
        margin-right: 202px;
        .center();
        font-size: 48px;
        color: white;
        font-weight: bold;
      }
      .search {
        width: 615px;
        height: 66px;
        background: #ffffff;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: 24px;
        position: relative;

        img {
          width: 22px;
          height: 22px;
          margin-right: 16px;
        }
        input {
          outline: none;
          border: none;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
        }
        /deep/ .placeholder_class {
          color: #b7bcce;
        }
        .button {
          width: 127px;
          height: 50px;
          background: #2957ff;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
          .center();
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .info {
    box-sizing: border-box;
    padding: 0 360px;
    padding-top: 24px;

    .info_div {
      box-sizing: border-box;
      padding: 0 24px;
      margin-top: 24px;
      background: #ffffff;
      .title {
        height: 80px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .name {
          font-size: 23px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #232428;
          margin-right: 8px;
        }
        .icon {
          width: 51px;
          height: 16px;
        }
      }

      .list {
        .list_div {
          height: 53px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          border-top: 1px dashed #c7ccdd;
          cursor: pointer;

          div:nth-child(1) {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #232428;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #696d7d;
          }
        }
      }
    }
    .paging {
      height: 108px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>